.container {
  min-height: calc(100vh - 93px);
  padding-inline: min(5vw, 69px);
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
}

.navigationButtons {
  margin-block: 23px;
}

.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.rut {
  margin-block: 28px 26px;
}

.modalButton {
  background: #F5F5FA;
  border-radius: 18px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7B34FF;
  margin-top: 26px;
  align-self: flex-start;
  cursor: pointer;
}

.button {
  margin-top: auto;
}
