.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  margin-bottom: 24px;
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #283234;
  margin-bottom: 2px;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #6d767a;
}
