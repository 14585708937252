.container {
  display: grid;
  /* Allow the component to easily change this parameter. */
  --min-col-width: 164px;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-col-width), 1fr));
  gap: 14px;
  justify-content: start;
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #454d65;
  margin-bottom: 2px;
  grid-column: 1 / -1;
}

.container > button {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: center;
  padding-block: 15px;
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 1px #DFE4F1;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #747D84;
  cursor:  pointer;
  outline: none;
}

.container > button:not(:disabled):is(:hover, :focus) {
  /* Use a box-shadow for the border
  to avoid shifting the layout. */
  box-shadow: 0px 0px 0px 2px #338dff;
}

.container > button:disabled {
  cursor: not-allowed;
}

.icon {
  width: 48px;
  height: 48px;
}
