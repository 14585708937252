.container {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.label, .error {
  display: block;
  padding-inline: 16px;
}

.label {
  color: #343B43;
}

.error {
  margin-top: 12px;
  font-weight: inherit;
  color: #FF346B;
}

.inputBorder {
  border-radius: 32.7px;
  padding: 2px;
  height: 48px;
  overflow: hidden;
  background-color: #E8ECF7;
  margin-top: 12px;
}

.inputBorder.hasError {
  background-color: #FF346B;
}

.inputBorder:not(.hasError):is(:hover, :focus-within) {
  background-image: linear-gradient(180deg , #7739FF, #3985FF);
}

.input {
  width: 100%;
  height: 100%;
  border-radius: 30.7px;
  color: #454D65;
  outline: none;
  padding-inline: 23px;
}

.input::placeholder {
  color: #717171;
}
