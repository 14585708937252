.container {
  background: #F5F5FA;
  border-radius: 4.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px;
}

.icon {
  color: #338DFF;
  margin-right: 12px;
}

.label, .rut {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14.4018px;
  line-height: 17px;
}

.label {
  color: #454D65;
  display: flex;
  align-items: center;
}

.rut {
  color: #878DA4;
}
