@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import '../node_modules/modern-normalize/modern-normalize.css';

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Roboto', sans-serif;
}

#root {
  width: min(800px , 100%);
  margin: auto;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.03);
}

.entity-icon {
  width: 86px;
  height: 86px;
  background-color: #F5F5FA;
  padding: 23px;
  border-radius: 9px;
}

.title {
  font-size: 24px;
  line-height: 28px;
  color: #338DFF;
  font-weight: bold;
}

.text {
  font-size: 16px;
  line-height: 23px;
  color: #747D84;
}

.input-label {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #343B43;
}

.input-wrapper {
  display: block;
  padding: 2px;
  background-color: #E8ECF7;
  border-radius: 35px;
}

.input-wrapper:focus-within,
.input-wrapper_filled {
  background: linear-gradient(180deg , #7739FF, #3985FF);
}

.input,
.select {
  display: block;
  color: #5C5971;
  border-radius: 33px;
  width: 100%;
  padding: 16px 23px;
  line-height: 16px;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  background-color: white;
}

.select {
  appearance: none;
  background-image: url("./assets/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 24px);
  background-position-y: 50%;
}

.input::placeholder {
  color: #717171;
  opacity: 1;
  font-weight: normal;
}

.input-error {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: #7B34FF;
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7B34FF;
  text-decoration: none;
  outline: none;
  align-self: flex-start;
}

.link:focus{
  outline: 1px solid #7B34FF;
  border-radius: 0.2em;
}

.button {
  display: block;
  width: 100%;
  color: #FFFFFF;
  background: linear-gradient(93.01deg, #7B34FF -3.51%, #338DFF 106.47%);
  transition: .2s;
  border-radius: 10em;
  font-size: 18px;
  line-height: 21px;
  padding: 14px 0;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.button:is(:hover, :focus){
  filter: brightness(80%);
}

.button:focus {
  outline: 2px solid #E8ECF7;
}

.button:disabled {
  color: #B5B8DA;
  background: unset;
  background-color: #E4E5F1;
  border: 1px solid #CDCFE6;
  pointer-events: none;
}

.button_outline {
  color: transparent;
  background-clip: text;
  position: relative;
}

.button_outline::before {
  position: absolute;
  content: "";
  background: linear-gradient(93.01deg, #7B34FF -3.51%, #338DFF 106.47%);
  transition: .2s;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  z-index: -2;
  border-radius: 10em;
}

.button_outline:is(:hover, :focus) {
  filter: none;
  outline: none;
}

.button_outline:is(:hover, :focus)::before {
  filter: brightness(80%);
}

.button_outline::after {
  position: absolute;
  content: "";
  background-color: #fff;
  transition: .2s;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 10em;
}

.button_outline:is(:hover, :focus)::after {
  filter: brightness(80%);
}

/* 
Use with SVG like:

<svg className="svg-spinner" viewBox="0 0 100 100">
  <circle />
</svg>

Notice the value of the viewBox attribute.
To customize it use an additional class (BEM mixin) and
use the width, height, --radius and --line-width properties.
--radius and --line-width are relative to the SVG coordinate
system.
*/
.svg-spinner {
  display: block;
  width: 100px;
  height: 100px;
  --radius: 45px;
  --line-width: 10px;
}

.svg-spinner circle {
  --perimeter: calc(2 * var(--radius) * 3.14);
  fill: transparent;
  cx: 50px;
  cy: 50px;
  r: var(--radius);
  stroke: #338dff;
  stroke-width: var(--line-width);
  stroke-dasharray: var(--perimeter);
  stroke-dashoffset: var(--perimeter);
  animation: svg-spinner_animation 1.5s infinite linear;
  transform: rotate(-90deg) translateX(-100px);
}

@keyframes svg-spinner_animation {
  0% {
    stroke-dashoffset: var(--perimeter);
  }

  50% {
    stroke-dashoffset: 0px;
  }

  100% {
    stroke-dashoffset: calc(-1 * var(--perimeter));
  }
}
