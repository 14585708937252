.gob-credentials-modal__background{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999;
}

.gob-credentials-modal__container{
    background-color: white;
    padding: 24px;
    border-radius: 16px;
    max-width: 460px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.gob-credentials-modal__image{
    margin-bottom: 36px;
    display: flex; 
    margin-left: auto;
    margin-right: auto;
}

.gob-credentials-modal__title{
    color: #338DFF;
    margin-bottom: 16px;
    text-align: center;
}

.gob-credentials-modal__text{
    color: #5C789C;
    font-weight: 500;
    line-height: 22px;
    text-align: center; 
    margin-bottom: 24px;
}

.gob-credentials-modal__text strong{
    font-weight: bold;
}

.gob-credentials-modal__highlight{
    color: #5C789C;
    border: solid;
    border-color: #E8ECF7;
    padding: 16px 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.gob-credentials-modal__highlight-line{
    display: inline-flex;
    align-items: center;
}

.gob-credentials-modal__highlight-icon{
    height: 24px;
    width: 24px;
    margin-right: 6px;
    fill: #338DFF;
}

.gob-credentials-modal__button{
    margin-top: 16px;
}
