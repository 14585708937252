.wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: white;
    border-radius: 30.7px;
    overflow: hidden;
}

.input {
    flex-grow: 1;
    height: 100%;
    padding-left: 23px;
    padding-right: 12px;
    outline: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #717171;
}

.button {
    flex-shrink: 0;
    padding: 6px;
    background-color: transparent;
    cursor: pointer;
    border-radius: 100%;
    margin-right: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:is(:hover, :focus) {
    background-color: rgba(0, 0, 0, 0.2);
}

.icon {
    height: 24px;
    width: 24px;
    color: #717171;
    border-radius: 24px;
}
