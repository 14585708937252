.container {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 36px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 9px;
}

/* Alert types */
.success {
  background-color: #DCEAFF;
  color: #647DA3;
}

.error {
  background-color: #FFE1DC;
  color: #945F57;
}

.alert {
  background-color: #FFEDDC;
  color: #806A56;
}
