.page-header {
  border-bottom: 1px solid #e9efff;
  padding: 32px 20px 12px 20px;
  top: 0px;
  background-color: #fff;
  position: sticky;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  z-index: 1000;
}

.page-header__title {
  margin-bottom: 18px;
  color: #373737;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.page-header__title-beta {
  background: linear-gradient(94.3deg, #7b34ff 3.37%, #338dff 100.88%);
  color: white;
  font-weight: 600;
  padding: 4px 8px 4px 8px;
  margin-left: 8px;
  border-radius: 999px;
  font-size: 16px;
}

.page-header__progress-meter {
  height: 7px;
  background-color: #e9efff;
  border-radius: 16px;
  overflow: hidden;
}

.page-header__progress-bar {
  height: 100%;
  width: 0;
  border-radius: 16px;
  background: linear-gradient(91.68deg, #7b34ff 5.99%, #338dff 65.68%);
  transition: width 0.5s;
}
