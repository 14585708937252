.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader__img {
  margin-bottom: 24px;
}

.loader__progress-bar {
  width: 269px;
  height: 16px;
  background: #F1F4FD;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin-bottom: 40px;
  overflow: hidden;
}

.loader__progress {
  background: #338DFF;
  box-shadow: inset 1px -3px 4px rgba(0, 0, 0, 0.13);
  border-radius: 20px;
  height: 100%;
  width: 0%;
  transition: width .3s;
}

.loader__step-indicator {
  background: #F5F5FA;
  border-radius: 32px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
}

.loader__step-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #76828F;
}

.loader__step-icon {
  margin-right: 6px;
}
