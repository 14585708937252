.container {
  min-height: calc(100vh - 93px);
  padding-inline: min(5vw, 69px);
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
  padding-top: 30px;
}

.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  margin-top: 30px;
}

.button {
  margin-top: auto;
}
