.container {
  min-height: calc(100vh - 93px);
  padding-inline: min(5vw, 69px);
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
}

.navigationButtons {
  margin-block: 23px;
}

.fieldsTitle {
  margin-block: 24px 16px;
  font-size: 16px;
  line-height: 19px;
  color: #454D65;
}

.fields {
  border-radius: 6px;
  background-color: #F5F5FA;
}

.field {
  padding: 16px 27px;
  display: flex;
  align-items: center;
}

.field:not(:last-child){
  box-shadow: inset 0px -1px 0px #E8ECF7;
}

.field :is(dt, dd) {
  font-size: 14px;
  line-height: 16px;
}

.field dt {
  font-weight: 600;
  color: #454D65;
  margin-bottom: 4px;
}

.field dd {
  color: #878DA4;
}

.fieldIcon {
  float: left;
  margin-right: 16px;
}

.button {
  margin-top: 40px;
}
