.container {
  min-height: calc(100vh - 93px);
  padding-inline: min(5vw, 69px);
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  position: relative;
}

.navigationButtons {
  position: absolute;
  top: 23px;
}

.loader, .imgWithMessage {
  margin-top: auto;
}

.buttons {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.button {
  width: auto;
  flex-grow: 1;
  padding-inline: 44px;
}

.alert {
  align-self: center;
  margin-top: 40px;
}
