.container {
  min-height: calc(100vh - 93px);
  padding-inline: min(5vw, 69px);
}

.navigationButtons {
  margin-block: 23px;
}

.banner {
  margin-bottom: 26px;
}
