.container {
  padding-inline: 19px;
  padding-block: 30px;
  min-height: calc(100vh - 93px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imgWithMessage {
  margin-top: auto;
}

.alert {
  margin-block: 40px auto;
}
