.container {
  display: flex;
  align-items: center;
  background: #f5f5fa;
  border-radius: 8px;
  padding: 30px 39px;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #747D84;
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #454D65;
  margin-bottom: 8px;
}

.icon {
  color: #338DFF;
  width: 64px;
  height: 64px;
  margin-right: 16px;
  flex-shrink: 0;
}
