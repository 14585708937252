.container {
  display: flex;
  justify-content: space-between;
}

.previous,
.next {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  color: #338dff;
  display: flex;
  align-items: center;
  padding: 4px;
}

:is(.previous, .next):hover {
  text-decoration: underline;
}

.icon {
  width: 20px;
  height: 20px;
}
